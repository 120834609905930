/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/indent */
import React, { useContext, useState } from 'react';
import Image from 'next/image';
import { Button, Typography, Card } from '@pwskills/rachnaui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import redDotIcon from '@/assets/icons/Ellipse 52.svg';
import LessonTypeIcons from '@/components/CareerPathLearn/LessonTypeIcons';
import LearnApi from '@/api/learn';
import { companyImage, domainUrl } from '@/utils/common/env.constants';
import Toaster from '@/components/common/Toaster';
import useDownloadCertificateStore from '@/store/useDownloadCertificateStore';
import { hideorShowReveChat } from '@/utils/helper/helperFunction';
import { COURSE_PURCHASE_TYPE } from '@/utils/common/common.constants';
import { CurrentDetailsEnums } from '@/GlobalEnums/enums';
import { GAevent } from '../../../utils/firebase.utils';
import UserDetailsContext from '../../../context/UserDetailsContext';

interface Pricing {
  isFree: boolean;
  IN: number;
  US: number;
  discount: number;
}

export type course = {
  thumbnailImgAltText: string | undefined;
  img: string;
  mode: string;
  title: string;
  _id: string;
  slug: string;
  pricing?: Pricing;
  lesson: { completed: number; total: number };
  section: { completed: number; total: number };
  overview: { language: string };
  recentlyAccessedLesson: {
    lessonId: string;
    dateAdded: string;
    milestoneId: string;
    title: string;
    type: string;
  };
  isActiveCourse: boolean;
  isActiveForPurchase: boolean;
  coursePurchaseType: string;
  courseType: string;
};

export type courseCard = {
  course: course;
  _id: string;
};
type props = {
  cardData: courseCard;
  isCareerPath: boolean;
  setLearnpageLoader: React.Dispatch<React.SetStateAction<boolean>>;
  pageFrom: string;
};

const CampusEdgeCard = ({ cardData, isCareerPath, setLearnpageLoader, pageFrom }: props) => {
  const router = useRouter();
  const [openToaster, setOpenToaster] = useState(false);
  const [toastMessage, setToastMessage] = useState({ isError: false, message: '' });
  const updateDownloadCertificateMeta = useDownloadCertificateStore(
    (state) => state.updateDownloadCertificateMeta
  );
  const lectureType = (type: string) => {
    switch (type) {
      case 'quiz_v2':
        return 'quiz';
      case 'quiz':
        return 'quiz';
      case 'notes':
        return 'notes';
      case 'test':
        return 'test';
      case 'video':
        return 'video';
      case 'assignment':
        return 'assignment';
      case 'assignment_v2':
        return 'assignment';
      case 'coding_assessment':
        return 'coding_assessment';
      case 'coding_quiz':
        return 'coding_quiz';
      case 'sectionResource':
        return 'sectionResource';
      case 'project':
        return 'project';
      default:
        return 'video';
    }
  };
  const titleToSlug = (title: string) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with dashes
      .replace(/[^a-z0-9-]/g, '') // Remove non-alphanumeric characters and dashes
      .replace(/-+/g, '-') // Replace multiple dashes with single dash
      .replace(/^-*/, '') // Remove leading dashes
      .replace(/-*$/, ''); // Remove trailing dashes
  };

  const getCourseDetailsFunction = async () => {
    if (isCareerPath) {
      try {
        const catchResponse = await LearnApi.getAllCourseDetails<any>(cardData?.course?._id);
        if (catchResponse) {
          hideorShowReveChat(!catchResponse?.data?.pricing?.isFree);
          if (catchResponse?.data?.sections[0]?.lessons[0]) {
            setLearnpageLoader(true);
            const url = `/learn/course/${cardData?.course?.slug}/${cardData?.course?._id}/lesson/${catchResponse?.data?.sections[0]?.lessons[0]}?from=${router?.asPath}&clickText=continue&page_from=my_course_page&section=your_courses`;
            if (catchResponse?.data?.courseMetaDetails?.canGenerateCertificate) {
              updateDownloadCertificateMeta({
                canGenerateCertificate:
                  catchResponse?.data?.courseMetaDetails?.canGenerateCertificate,
                certificateBenchmark: catchResponse?.data?.courseMetaDetails?.certificateBenchmark,
              });
            }
            router.push(url);
          } else {
            setToastMessage({ isError: true, message: 'Course is not started yet' });
            setOpenToaster(true);
          }
        }
      } catch (error) {
        setLearnpageLoader(false);
        setToastMessage({ isError: true, message: 'Something went wrong' });
        setOpenToaster(true);
      }
    } else {
      try {
        const catchResponse = await LearnApi.getAllCourseDetails<any>(cardData?.course?._id);

        if (catchResponse) {
          setLearnpageLoader(true);
          if (catchResponse?.data?.sections[0]?.lessons[0]) {
            setLearnpageLoader(true);
            const url = `/learn/course/${
              cardData?.course?.slug ? cardData?.course?.slug : titleToSlug(cardData?.course?.title)
            }/${cardData?.course?._id}/lesson/${
              catchResponse?.data?.sections[0]?.lessons[0]
            }?from=${
              router?.asPath
            }&clickText=continue&page_from=my_course_page&section=your_courses`;
            if (catchResponse?.data?.courseMetaDetails?.canGenerateCertificate) {
              updateDownloadCertificateMeta({
                canGenerateCertificate:
                  catchResponse?.data?.courseMetaDetails?.canGenerateCertificate,
                certificateBenchmark: catchResponse?.data?.courseMetaDetails?.certificateBenchmark,
              });
            }
            hideorShowReveChat(!catchResponse?.data?.pricing?.isFree);
            router.push(url);
          } else {
            setToastMessage({ isError: true, message: 'Course is not started yet' });
            setOpenToaster(true);
          }
        }
      } catch (error) {
        try {
          const catchResponse = await LearnApi.getAllCourseDetails<any>(cardData?.course?._id);
          let url = '';
          if (catchResponse?.data?.sections[0]?.lessons[0]) {
            url = `/learn/course/${
              cardData?.course?.slug ? cardData?.course?.slug : titleToSlug(cardData?.course?.title)
            }/${cardData?.course?._id}/lesson/${
              catchResponse?.data?.sections[0]?.lessons[0]
            }?from=${
              router?.asPath
            }&clickText=continue&page_from=my_course_page&section=your_courses`;
            if (catchResponse?.data?.courseMetaDetails?.canGenerateCertificate) {
              updateDownloadCertificateMeta({
                canGenerateCertificate:
                  catchResponse?.data?.courseMetaDetails?.canGenerateCertificate,
                certificateBenchmark: catchResponse?.data?.courseMetaDetails?.certificateBenchmark,
              });
            }
            router.push(url);
          } else {
            setLearnpageLoader(false);
            setToastMessage({ isError: true, message: 'Course has not started yet' });
            setOpenToaster(true);
          }
        } catch {
          setLearnpageLoader(false);
          setToastMessage({ isError: true, message: 'Something went wrong' });
          setOpenToaster(true);
        }
      }
    }
    setLearnpageLoader(false);
  };

  const openNormalCourseLectureTrack = () => {
    // have to add
    // &clickText=continue&page_from=my_course_page&section=your_courses
    if (cardData?.course?.recentlyAccessedLesson?.lessonId) {
      if (isCareerPath)
        router.push(
          `/learn/course/${cardData?.course?.slug}/${cardData?.course?._id}/lesson/${cardData?.course?.recentlyAccessedLesson?.lessonId}?milestoneId=${cardData?.course?.recentlyAccessedLesson?.milestoneId}&lessonName=${cardData?.course?.title}?from=${router?.asPath}&clickText=continue&page_from=my_course_page&section=your_courses`
        );
      else {
        hideorShowReveChat(!cardData?.course?.pricing?.isFree);
        router.push(
          `/learn/course/${
            cardData?.course?.slug ? cardData?.course?.slug : titleToSlug(cardData?.course?.title)
          }/${cardData?.course?._id}/lesson/${
            cardData?.course?.recentlyAccessedLesson?.lessonId
          }?from=${router?.asPath}&clickText=continue&page_from=my_course_page&section=your_courses`
        );
      }
    } else if (isCareerPath) {
      router.push(
        `/learn/career-path/${cardData?.course?.slug}/${cardData?.course?._id}/dashboard?from=${router.asPath}&clickText=continue&page_from=my_course_page`
      );
    } else {
      getCourseDetailsFunction();
    }
  };

  const { user } = useContext(UserDetailsContext);
  const userData = user;

  const sendGAEvent = (
    courseName: string | string[] | undefined,
    courseId: string,
    sectionName: string
  ) => {
    GAevent('sk_course_watch', {
      user_id: userData._id,
      user_name: `${userData.firstName} ${userData.lastName}`,
      user_email: userData.email,
      user_phonenumber: `${userData.isdCode} ${userData.phoneNumber}`,
      course_name: courseName,
      course_id: courseId,
      section_name: sectionName,
    });
  };

  const onContinueClick = () => {
    if (isCareerPath) {
      router.push(
        `/learn/career-path/${cardData?.course?.slug}/${cardData?.course?._id}/dashboard?from=${router.asPath}&clickText=continue&page_from=my_course_page`
      );
    } else {
      openNormalCourseLectureTrack();
    }
  };

  const handleClick = () => {
    localStorage.setItem(CurrentDetailsEnums.CURRENT_COURSE, cardData.course.title);
    sendGAEvent(cardData.course.title, cardData.course._id, 'Continue Button');
  };

  const giveCourseProgressFunction = (data: courseCard) => {
    const progressPercentage =
      (((data?.course?.lesson?.completed as number) / data?.course?.lesson?.total) as number) * 100;
    return `${Math.round(progressPercentage)}%`;
  };
  const onExploreClick = () => {
    let link = `${domainUrl}/course/${cardData?.course?.slug}`;
    if (pageFrom === 'learn_page') link += `?from=${pageFrom}`;
    window.open(link, '_self');
  };
  return (
    <>
      <Card
        className="m-0 relative cursor-pointer h-full flex flex-col w-full !px-7 !py-6 medium:!p-[14px] !shadow-none !bg-white border-[1px] border-solid  border-gray-[#EFEFEF]"
        onClick={() => {
          openNormalCourseLectureTrack();
        }}
      >
        <div className="flex flex-col gap-2 h-full">
          <div className="flex flex-col gap-[14px]  medium:!w-[236px]">
            <div className="flex flex-col rounded-lg  relative">
              <div className="!w-full !h-auto medium:!w-[236px] relative">
                <img
                  // upload thumbnail logic is updated - newer uploads will return s3 bucket url and older one will return id
                  src={`${
                    cardData?.course?.img && cardData?.course?.img.startsWith('https')
                      ? cardData?.course?.img
                      : `${companyImage}/thumbnails/${cardData?.course?.img}`
                  }`}
                  alt={cardData?.course?.thumbnailImgAltText || ''}
                  width={236}
                  height={130}
                  className="!w-full  !h-auto medium:!h-[130px] medium:!w-[236px] "
                  style={{ height: '130px', width: '236px' }}
                />
                <div className="opacity-0  hover:!opacity-100 !z-20">
                  <div
                    className="w-full h-full absolute top-0 !z-20"
                    style={{
                      backgroundImage:
                        'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.83) 100%)',
                    }}
                  />
                  <Typography
                    component="body-small"
                    variant="regular"
                    className=" text-white absolute bottom-[3px] z-2 left-1/2 transform -translate-x-1/2 !z-20"
                  >
                    {`${
                      cardData?.course?.lesson?.total === 0
                        ? '0%'
                        : giveCourseProgressFunction(cardData)
                    } complete
                  `}
                  </Typography>
                </div>
              </div>
              <div className="!w-full overflow-hidden medium:!w-[236px] bg-[#FFDBD3] rounded-b-lg h-[6px]">
                <div
                  className="bg-[#E97862] h-[6px] "
                  style={{
                    width:
                      cardData?.course?.lesson?.total === 0
                        ? 0
                        : giveCourseProgressFunction(cardData),
                  }}
                />
              </div>
              {cardData?.course?.overview?.language && (
                <div className="absolute flex gap-1 bg-[#EFF5FF] px-1 right-2 bottom-[8px] rounded-[2px] items-center !z-10">
                  <Image src={redDotIcon} alt="icon" />
                  <Typography component="body-tiny" className="text-[#3D3D3D] capitalize">
                    {cardData?.course?.overview?.language}
                  </Typography>
                </div>
              )}
            </div>
            <Typography
              component="body-regular"
              variant="bold"
              className=" text-gray-850 font-inter"
            >
              {cardData?.course?.title}
            </Typography>
          </div>

          <div className="flex flex-col flex-grow  items-start justify-end gap-4">
            <div className="flex items-center gap-x-2">
              {/* this icon will be rendered conditionally based on the api details */}
              {cardData?.course?.lesson?.completed === 0 ? (
                <Typography component="body-small" variant="bold" className="text-gray-600 ">
                  Yet to be started
                </Typography>
              ) : (
                <Link
                  href={
                    isCareerPath
                      ? `/learn/course/${cardData?.course?.slug}/${cardData?.course?._id}/lesson/${cardData?.course?.recentlyAccessedLesson?.lessonId}?milestoneId=${cardData?.course?.recentlyAccessedLesson?.milestoneId}&lessonName=${cardData?.course?.title}&from=${router?.asPath}`
                      : `/learn/course/${cardData?.course?.slug}/${cardData?.course?._id}/lesson/${cardData?.course?.recentlyAccessedLesson?.lessonId}?from=${router?.asPath}`
                  }
                >
                  <button type="button" onClick={handleClick}>
                    <div className="flex flex-row gap-1 items-center">
                      {isCareerPath ? (
                        <LessonTypeIcons
                          variant="default"
                          component={lectureType(cardData?.course?.recentlyAccessedLesson?.type)}
                        />
                      ) : (
                        ''
                      )}

                      <Typography
                        component="body-small"
                        variant="bold"
                        className="text-gray-600 line-clamp1  underline underline-offset-4"
                      >
                        {isCareerPath ? cardData?.course?.recentlyAccessedLesson?.title : ''}
                      </Typography>
                    </div>
                  </button>
                </Link>
              )}
            </div>

            <div className="flex justify-between gap-9 medium:!gap-[6px] w-full ">
              {/* Todo: update the link to course description */}

              {/* course is inactive explore button should not be visible */}

              {/* Note: isActiveForPurchase=false && isMappedToOtherCourse && isCourseType=="Plan" then show explore */}
              {cardData?.course?.isActiveCourse === true &&
                cardData?.course?.coursePurchaseType === COURSE_PURCHASE_TYPE.PLAN &&
                cardData?.course?.isActiveForPurchase === true && (
                  <Button
                    variant="secondary"
                    className="!px-5 !py-[9px] w-full !rounded !text-sm !font-inter"
                    onClick={(e) => {
                      e.stopPropagation();
                      onExploreClick();
                    }}
                  >
                    Explore
                  </Button>
                )}

              {cardData?.course?.isActiveCourse === true &&
                cardData?.course?.coursePurchaseType === COURSE_PURCHASE_TYPE.INDIVIDUAL && (
                  <Button
                    variant="secondary"
                    className="!px-5 !py-[9px] w-full !rounded !text-sm !font-inter"
                    onClick={(e) => {
                      e.stopPropagation();
                      onExploreClick();
                    }}
                  >
                    Explore
                  </Button>
                )}

              {/* {cardData?.course?.isActiveForPurchase === false &&
                cardData?.course?.isActiveCourse === true && (
                  <Button
                    variant="secondary"
                    className="!px-5 !py-[9px] w-full !rounded !text-sm !font-inter"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Link href={`/course/${cardData?.course?.slug}`}>Explore</Link>
                  </Button>
                )} */}

              <Button
                variant="primary"
                className="border border-orange-500 !px-5 !py-[9px] w-full !rounded !text-sm !font-inter"
                onClick={(e) => {
                  e.stopPropagation();
                  onContinueClick();
                  handleClick();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Card>
      {openToaster && (
        <Toaster
          open={openToaster}
          setOpen={setOpenToaster}
          text={toastMessage.message}
          variant={toastMessage?.isError ? 'error' : 'success'}
        />
      )}
    </>
  );
};

export default CampusEdgeCard;
