import React from 'react';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setSearching: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBox = ({ className, searchText, setSearchText, setSearching, ...rest }: Props) => {
  return (
    <div
      className={`border border-stroke-200 bg-white flex items-center gap-x-2 py-3 px-4 rounded w-[75%] relative ${className}`}
      {...rest}
    >
      <svg
        className="w-6 h-6 text-gray-500"
        viewBox="0 0 512 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M464 428L339.92 303.9C359.916 276.421 370.673 243.304 370.64 209.32C370.64 120.37 298.27 48 209.32 48C120.37 48 48 120.37 48 209.32C48 298.27 120.37 370.64 209.32 370.64C243.304 370.673 276.421 359.916 303.9 339.92L428 464L464 428ZM209.32 319.69C187.488 319.692 166.146 313.22 147.992 301.092C129.839 288.964 115.69 271.725 107.334 251.555C98.9785 231.385 96.7917 209.191 101.05 187.778C105.309 166.366 115.822 146.697 131.26 131.26C146.697 115.822 166.366 105.309 187.778 101.05C209.191 96.7917 231.385 98.9785 251.555 107.334C271.725 115.69 288.964 129.839 301.092 147.992C313.22 166.146 319.692 187.488 319.69 209.32C319.656 238.581 308.016 266.634 287.325 287.325C266.634 308.016 238.581 319.656 209.32 319.69Z"
          fill="currentColor"
        />
      </svg>
      <input
        className="focus:outline-none text-gray-650 w-full"
        type="text"
        placeholder="Search in your courses"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          if (e.target.value !== '') setSearching(true);
          else {
            setTimeout(() => {
              setSearching(false);
            }, 500);
          }
        }}
      />
      {searchText !== '' && (
        <button type="button" onClick={() => setSearchText('')}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M11.3255 10L15.0364 6.2891C15.2125 6.11329 15.3116 5.87472 15.3118 5.62587C15.312 5.37702 15.2134 5.13827 15.0376 4.96215C14.8618 4.78603 14.6232 4.68696 14.3743 4.68674C14.1255 4.68652 13.8867 4.78517 13.7106 4.96098L9.99967 8.67191L6.28874 4.96098C6.11262 4.78486 5.87374 4.68591 5.62467 4.68591C5.3756 4.68591 5.13673 4.78486 4.96061 4.96098C4.78449 5.1371 4.68555 5.37597 4.68555 5.62504C4.68555 5.87411 4.78449 6.11298 4.96061 6.2891L8.67155 10L4.96061 13.711C4.78449 13.8871 4.68555 14.126 4.68555 14.375C4.68555 14.6241 4.78449 14.863 4.96061 15.0391C5.13673 15.2152 5.3756 15.3142 5.62467 15.3142C5.87374 15.3142 6.11262 15.2152 6.28874 15.0391L9.99967 11.3282L13.7106 15.0391C13.8867 15.2152 14.1256 15.3142 14.3747 15.3142C14.6237 15.3142 14.8626 15.2152 15.0387 15.0391C15.2149 14.863 15.3138 14.6241 15.3138 14.375C15.3138 14.126 15.2149 13.8871 15.0387 13.711L11.3255 10Z"
              fill="#989DA5"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default SearchBox;
