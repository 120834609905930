/* eslint-disable @typescript-eslint/indent */
import { Button, Loader, Navbar, Typography } from '@pwskills/rachnaui';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { ICategory, IUser } from '@pwskills/rachnaui/components/Navbar/Navbar.types';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { ACCESS_TOKEN, CourseTypes } from '@/utils/common/common.constants';
import useInitLogin from '@/hooks/useInitLogin';
import SearchBox from '@/components/LearnDashboard/SearchBox';
import ListingLayout from '@/components/LearnDashboard/UI/ListingLayout';
import NoCoursesIcon from '@/assets/icons/NoCoursesIcon.svg';
import { getProfileUrl, logout } from '@/utils/helper/helperFunction';
import LearnApi from '@/api/learn';
import { ProductListNavLink } from '@/utils/homePage/home.constants';
import { accountUrl, baseApiUrl, courseThumbnail, domainUrl } from '@/utils/common/env.constants';
import EmptyState from '@/components/common/EmptyState';
import CampusEdgeCard, { courseCard } from '@/components/LearnDashboard/UI/Card';
import withAuth from '@/HOCs/withAuth';
import Spinner from '@/components/common/Loader';
import useLocationInfo from '@/hooks/useLocationInfo';
import { classNames, randomId } from '@pwskills/rachnaui/utils';
import useDownloadCertificateStore from '@/store/useDownloadCertificateStore';
import useVideoLessonCompleted from '@/store/VideoLectureStore/useLessonCompletedStatus';
// import useHelpWidgetUrl from '@/hooks/useHelpWidgetUrl';
import { HelpCentreToggle } from '@/interfaces/api.types';
import HomePageApi from '@/api/homePage';
import { IError } from '@/interfaces/common';
import sendEventOnClick from '@/utils/DataLayerEventFunctions';
// import SupportIcon from '@/assets/icons/Support';
import MasterClassCard from '@/components/MasterClass/Card';
import MasterClassApi from '@/api/masterclass';
import { IMasterClass } from '@/interfaces/masterclass';
import { GAevent } from '../../utils/firebase.utils';
import OffersStrip from '../../components/common/OffersStrip/OffersStrip';

const Learn = () => {
  const [token, setToken] = useState('');
  // const [showSideView, setShowSideView] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  // const [careerPathCoursesOrderText, setCareerPathCoursesOrderText] = useState('Newest');
  // const [careerPathCoursesOrderType, setCareerPathCoursesOrderType] = useState(-1);
  const [masterClassLoader, setMasterClassLoader] = useState<boolean>(true);
  const [masterClasses, setMasterClasses] = useState<Array<IMasterClass>>([]);
  const [learnPageLoader, setLearnpageLoader] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [coursesLoader, setCoursesLoader] = useState<boolean>(true);
  const [courses, setCourses] = useState<Array<courseCard>>([]);

  // const [iframeLoading, setIframeLoading] = useState(false);
  const { user } = useInitLogin();
  const { host, origin } = useLocationInfo();
  const clearCompletedLessons = useVideoLessonCompleted((state) => state.clearCompletedLessons);
  const resetDownloadCertificateMeta = useDownloadCertificateStore(
    (state) => state.resetDownloadCertificateMeta
  );
  const router = useRouter();
  const enableHelpCentre = process.env.NEXT_PUBLIC_HELP_CENTRE_ENABLE === HelpCentreToggle.true;

  const obj: IUser = {
    firstName: user?.firstName,
    lastName: user?.lastName as string,
    email: user?.email,
    isdCode: user?.isdCode,
    _id: user?._id,
    verified: user?.verified,
    phoneNumber: user?.phoneNumber,
    img: user?.img && getProfileUrl(user?.img),
  };

  // const helpWidgetUrl = useHelpWidgetUrl();
  const searchApi = `${baseApiUrl}/v2/course/search?limit=10&page=1`;

  const [categoryData, setCategoryData] = useState<ICategory[]>([]);

  const fetchCompanyAndFaqsDetails = async () => {
    try {
      const catchResponse = await HomePageApi.getCompanyAndFaqs();

      if (catchResponse) {
        const categoryDetails = catchResponse?.data?.categories;
        setCategoryData(categoryDetails);
      }
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error((error as IError)?.error);
    }
  };

  useEffect(() => {
    fetchCompanyAndFaqsDetails();
  }, []);

  const fetchNormalCourses = async (order: number) => {
    try {
      const response = await LearnApi.getUserCourses<{ data: courseCard[] }>(
        order,
        'false',
        encodeURIComponent(searchQuery)
      );

      const allCourses = response.data;
      return allCourses;
    } catch (error) {
      return [];
      /* empty */
    }
  };

  const fetchCareerPathCourses = async (order: number) => {
    try {
      const response = await LearnApi.getUserCourses<{ data: courseCard[] }>(
        order,
        'true',
        encodeURIComponent(searchQuery)
      );

      const allCourses = response.data;
      return allCourses;
    } catch (error) {
      return [];
      /* empty */
    }
  };

  const fetchMasterClass = async () => {
    setMasterClassLoader(true);
    try {
      const masterClassRes = await MasterClassApi.getMasterClassList({
        isUserEnrolledList: true,
        isCertificateData: true,
      });
      return masterClassRes.data.masterClassData;
    } catch (error) {
      setMasterClassLoader(false);
      return [];
      /* empty */
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setToken(localStorage.getItem(ACCESS_TOKEN) || '');
    }

    // reset the store for other course
    resetDownloadCertificateMeta();
    // clear the completed lesson while switching lessons
    clearCompletedLessons();
  }, []);

  // useEffect(() => {
  //   if (careerPathCoursesOrderType === -1) setCareerPathCoursesOrderText('Newest');
  //   else setCareerPathCoursesOrderText('Oldest');
  // }, [careerPathCoursesOrderType]);

  useEffect(() => {
    setIsApiLoading(true);
    const timeOut = setTimeout(async () => {
      const [normalCourses, careerCourses, masterClassCourses] = await Promise.all([
        fetchNormalCourses(-1),
        fetchCareerPathCourses(-1),
        fetchMasterClass(),
      ]);
      setCourses([...normalCourses, ...careerCourses]);
      setCoursesLoader(false);

      const filteredMasterClass = masterClassCourses.filter((masterclass) =>
        masterclass.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (searchQuery.length) {
        setMasterClasses(filteredMasterClass);
      } else setMasterClasses(masterClassCourses);

      setMasterClassLoader(false);
      setIsApiLoading(false);
    }, 300);
    return () => clearTimeout(timeOut);
  }, [searchQuery]);

  // const listingLayoutButtonCareerPathCourse: textAndFunctionType = {
  //   name: careerPathCoursesOrderText,
  //   onClick: () => {
  //     fetchCareerPathCourses(careerPathCoursesOrderType === -1 ? 1 : -1);
  //     setCareerPathCoursesOrderType(careerPathCoursesOrderType === -1 ? 1 : -1);
  //   },
  // };

  // const listingLayoutButtonNormalCourse: textAndFunctionType = {
  //   name: normalCoursesOrderText,
  //   onClick: () => {
  //     // fetchNormalCourses(normalCoursesOrderType === -1 ? 1 : -1);
  //     setNormalCoursesOrderType(normalCoursesOrderType === -1 ? 1 : -1);
  //   },
  // };

  const handleLogoutClick = () => {
    GAevent('sk_logout_click', {
      user_id: user._id,
      user_name: `${user.firstName} ${user.lastName}`,
      user_email: user.email,
      user_phonenumber: `${user.isdCode} ${user.phoneNumber}`,
      source: 'navbar',
    });
  };

  // const openSupportSlide = () => {
  //   setShowSideView(true);
  //   setIframeLoading(true);
  // };

  // const closeSupportSlide = () => {
  //   setShowSideView(false);
  // };

  // const onIframeLoad = () => {
  //   setIframeLoading(false);
  // };

  return (
    <div>
      {learnPageLoader ? (
        <div className="flex w-full h-screen items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="relative">
          <div className="!sticky top-0 z-[99999]">
            <OffersStrip />
          </div>

          <main>
            <nav
              className={classNames(
                '[&>div>div>div>div>div>ul>li:first-child>span:last-child]:!break-words',
                'z-[9999] relative'
              )}
            >
              <Navbar
                category={categoryData}
                cardImgLink={courseThumbnail}
                homeLink={origin}
                siteMetaApi={`${baseApiUrl}/v2/course/sitemeta?platformType=main&random_id=${randomId()}`}
                aTag={Link}
                menu={ProductListNavLink}
                user={obj}
                isCoursesMenu
                enableHelpCentre={enableHelpCentre}
                onSupportClick={() => {
                  router.push('/support');
                }}
                onProfileClick={() => {
                  window.location.href = `${accountUrl}/profile`;
                }}
                onCoursesClick={() => {
                  router.push('/learn');
                }}
                token={token}
                onLoginRegisterClick={() => {
                  window.location.href = `${accountUrl}/login?domain=${host}&redirectUrl=${router.pathname}&loginType=cookie`;
                }}
                variant="search"
                onLogoutClick={() => {
                  handleLogoutClick();
                  logout();
                }}
                searchApi={searchApi}
                sendEventOnClick={sendEventOnClick}
                domain={domainUrl}
                baseApiUrl={baseApiUrl}
                pageName="learn_page"
              />
            </nav>

            <div>
              <div className="flex flex-col items-center mx-auto px-4 py-9 medium:!p-8 gap-9  medium:gap-y-8  w-full  large:!max-w-[1184px]">
                <SearchBox
                  searchText={searchQuery}
                  setSearchText={setSearchQuery}
                  setSearching={setIsSearching}
                  className="w-full"
                />
                {isSearching && courses?.length === 0 && masterClasses?.length === 0 ? (
                  <ListingLayout
                    title="No Search Result"
                    classname="flex flex-col relative gap-9 medium:gap-6 w-full"
                    childClass="flex flex-col medium:flex-row flex-wrap gap-3 medium:gap-5 w-full medium:!justify-start large:!justify-start"
                    hideButton
                  >
                    <EmptyState message="No result found" />
                  </ListingLayout>
                ) : !isApiLoading &&
                  masterClasses.length === 0 &&
                  courses?.length === 0 &&
                  !coursesLoader &&
                  searchQuery?.length === 0 ? (
                  <div className="flex gap-6 flex-col justify-center items-center">
                    <Typography variant="bold" component="h3">
                      No Courses Found
                    </Typography>
                    <Image src={NoCoursesIcon} alt="NoCoursesIcon" />
                    <Typography variant="regular" component="body-small" className="text-gray-650">
                      See our huge variety of career oriented course and start learning
                    </Typography>
                    <Link href="/courses">
                      <Button size="small">Explore Now</Button>
                    </Link>
                  </div>
                ) : isApiLoading ? (
                  <Spinner />
                ) : (
                  <>
                    {courses?.length > 0 && (
                      <ListingLayout
                        title="Courses"
                        classname="flex flex-col relative gap-9 medium:gap-6 w-full"
                        childClass="flex flex-col medium:flex-row flex-wrap gap-3 medium:gap-5 w-full medium:!justify-start large:!justify-start"
                        // button={listingLayoutButtonCareerPathCourse}
                        hideButton
                      >
                        {coursesLoader ? (
                          <div className="flex items-center justify-center w-[100%] h-[300px]">
                            <Loader />
                          </div>
                        ) : courses?.length > 0 ? (
                          courses?.map((careerObj) => (
                            <div className="w-full medium:max-w-[265px]">
                              <CampusEdgeCard
                                key={careerObj?.course?._id}
                                cardData={careerObj}
                                isCareerPath={
                                  careerObj?.course?.courseType === CourseTypes.PLACEMENT
                                }
                                setLearnpageLoader={setLearnpageLoader}
                                pageFrom="learn_page"
                              />
                            </div>
                          ))
                        ) : (
                          <EmptyState
                            message={
                              isSearching ? 'No result found' : 'Oh no! Nothing for meantime.'
                            }
                          />
                        )}
                      </ListingLayout>
                    )}
                    {/* {normalCourses?.length > 0 && (
                      <ListingLayout
                        title="Courses"
                        classname="flex flex-col relative gap-9 medium:gap-6 w-full"
                        childClass="flex flex-col medium:flex-row flex-wrap gap-3 medium:gap-5 w-full medium:!justify-start large:!justify-start"
                        button={listingLayoutButtonNormalCourse}
                        hideButton
                      >
                        {normalCourseLoader ? (
                          <div className="flex items-center justify-center w-[100%] h-[300px]">
                            <Loader />
                          </div>
                        ) : normalCourses?.length > 0 ? (
                          normalCourses?.map((normalCoursessingleObj) => (
                            <div className="w-full medium:max-w-[265px]">
                              <CampusEdgeCard
                                key={normalCoursessingleObj?.course?._id}
                                cardData={normalCoursessingleObj}
                                isCareerPath={false}
                                setLearnpageLoader={setLearnpageLoader}
                                pageFrom="learn_page"
                              />
                            </div>
                          ))
                        ) : (
                          <EmptyState
                            message={
                              isSearching ? 'No result found' : 'Oh no! Nothing for meantime.'
                            }
                          />
                        )}
                      </ListingLayout>
                    )} */}
                    {masterClasses?.length > 0 && (
                      <ListingLayout
                        title="Masterclass"
                        classname="flex flex-col relative gap-9 medium:gap-6 w-full"
                        childClass="flex flex-col medium:flex-row flex-wrap gap-3 medium:gap-5 w-full medium:!justify-start large:!justify-start"
                        hideButton
                      >
                        {masterClassLoader ? (
                          <div className="flex items-center justify-center w-[100%] h-[300px]">
                            <Loader />
                          </div>
                        ) : masterClasses?.length > 0 ? (
                          masterClasses?.map((masterClass) => (
                            <MasterClassCard
                              data={masterClass}
                              smallerCards
                              learnPage
                              source="masterclasses"
                            />
                          ))
                        ) : (
                          <EmptyState
                            message={
                              isSearching ? 'No result found' : 'Oh no! Nothing for meantime.'
                            }
                          />
                        )}
                      </ListingLayout>
                    )}
                  </>
                )}
              </div>
              {/* {enableHelpCentre && (
                <div
                  className="fixed bottom-10 right-12 z-40 px-4 py-2 flex border border-orange-500 rounded-3xl bg-orange-50 cursor-pointer"
                  onClick={openSupportSlide}
                >
                  <Typography component="body-small" variant="bold" className="text-orange-500">
                    Support
                  </Typography>
                  <SupportIcon color="orange" size={20} className="ml-3" />
                </div>
              )} */}
              {/* {showSideView && (
                <div>
                  <div
                    className="fixed top-0 left-0 w-screen h-screen z-40 backdrop"
                    onClick={closeSupportSlide}
                  />
                  <div className="fixed top-0 right-0 z-50 w-3/6 h-full bg-white">
                    {iframeLoading ? (
                      <div className="flex justify-center items-center w-full h-full">
                        <Loader />
                      </div>
                    ) : undefined}
                    <iframe
                      title="help-centre-learn"
                      src={helpWidgetUrl}
                      className="w-full h-full"
                      onLoad={onIframeLoad}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </main>
        </div>
      )}
    </div>
  );
};

export default withAuth(Learn);
