import React, { useEffect, useRef } from 'react';

type Props = {
  children: React.ReactNode;
  onOutsideClick: () => void;
};

const OutsideClickHandler = (props: Props) => {
  const { children, onOutsideClick } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current) {
        // eslint-disable-next-line no-console
        if (!containerRef.current.contains(event.target as unknown as Node)) {
          onOutsideClick();
        }
      }
    };
    if (containerRef) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return <div ref={containerRef}>{children}</div>;
};

export default OutsideClickHandler;
