/* eslint-disable no-console */
import { LoginType } from '@/GlobalEnums/enums';
import useInitLogin from '@/hooks/useInitLogin';
import { ACCESS_TOKEN } from '@/utils/common/common.constants';
import { accountUrl } from '@/utils/common/env.constants';
import { useUTMParameters } from '@pwskills/rachnaui/hooks';
import { useRouter } from 'next/router';
import React, { ComponentType, useEffect } from 'react';

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>): ComponentType<P> => {
  const WithAuth = (props: P) => {
    const router = useRouter();
    const utmQuery = useUTMParameters();
    const { lessonId, courseId } = router.query;
    const { isLoading, user } = useInitLogin();

    useEffect(() => {
      // check stored access token

      const token = localStorage.getItem(ACCESS_TOKEN);
      if (typeof window !== 'undefined' && router.isReady && !token && !isLoading) {
        const newUrl = new URL(`${accountUrl}/login`);
        newUrl.searchParams.set('domain', window.location.host);
        newUrl.searchParams.set('redirectUrl', router.asPath);
        newUrl.searchParams.set('loginType', LoginType.COOKIE);
        if (utmQuery) {
          Object.entries(utmQuery).forEach(([key, value]) => {
            newUrl.searchParams.set(key, value as string);
          });
        }
        window.location.href = newUrl.href;
      }
    }, [isLoading, user, router, lessonId, courseId, router]);

    return <WrappedComponent {...props} />;
  };
  return WithAuth;
};

export default withAuth;
