/* eslint-disable @typescript-eslint/indent */
import React, { useMemo } from 'react';

type Icon = {
  [key: string]: React.ReactNode;
};

type IconProps = {
  color: string;
};

type IconographyType = {
  component:
    | 'video'
    | 'notes'
    | 'quiz'
    | 'test'
    | 'project'
    | 'assignment'
    | 'coding_assessment'
    | 'coding_quiz'
    | 'sectionResource';
  variant?: 'current' | 'default' | 'upcoming' | 'incomplete';
};

const AssignmentIcon = ({ color }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 16C28 9.375 22.625 4 16 4C9.375 4 4 9.375 4 16C4 22.625 9.375 28 16 28C22.625 28 28 22.625 28 16Z"
        fill="white"
        stroke={color}
        strokeMiterlimit="10"
      />
      <g clipPath="url(#clip0_2475_73199)">
        <path
          d="M20.6667 9.99984H17.88C17.6 9.2265 16.8667 8.6665 16 8.6665C15.1333 8.6665 14.4 9.2265 14.12 9.99984H11.3333C10.6 9.99984 10 10.5998 10 11.3332V20.6665C10 21.3998 10.6 21.9998 11.3333 21.9998H20.6667C21.4 21.9998 22 21.3998 22 20.6665V11.3332C22 10.5998 21.4 9.99984 20.6667 9.99984ZM16 9.99984C16.3667 9.99984 16.6667 10.2998 16.6667 10.6665C16.6667 11.0332 16.3667 11.3332 16 11.3332C15.6333 11.3332 15.3333 11.0332 15.3333 10.6665C15.3333 10.2998 15.6333 9.99984 16 9.99984ZM16.6667 19.3332H13.3333C12.9667 19.3332 12.6667 19.0332 12.6667 18.6665C12.6667 18.2998 12.9667 17.9998 13.3333 17.9998H16.6667C17.0333 17.9998 17.3333 18.2998 17.3333 18.6665C17.3333 19.0332 17.0333 19.3332 16.6667 19.3332ZM18.6667 16.6665H13.3333C12.9667 16.6665 12.6667 16.3665 12.6667 15.9998C12.6667 15.6332 12.9667 15.3332 13.3333 15.3332H18.6667C19.0333 15.3332 19.3333 15.6332 19.3333 15.9998C19.3333 16.3665 19.0333 16.6665 18.6667 16.6665ZM18.6667 13.9998H13.3333C12.9667 13.9998 12.6667 13.6998 12.6667 13.3332C12.6667 12.9665 12.9667 12.6665 13.3333 12.6665H18.6667C19.0333 12.6665 19.3333 12.9665 19.3333 13.3332C19.3333 13.6998 19.0333 13.9998 18.6667 13.9998Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2475_73199">
          <rect width="16" height="16" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const NotesIcon = ({ color }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3.5C16.6926 3.5 20.5 7.30739 20.5 12C20.5 16.6926 16.6926 20.5 12 20.5C7.30739 20.5 3.5 16.6926 3.5 12C3.5 7.30739 7.30739 3.5 12 3.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <g clipPath="url(#clip0_9895_81537)">
        <path
          d="M11.3374 9.22114C10.7411 8.93645 9.90763 8.79699 8.7918 8.79204C8.68656 8.79061 8.58339 8.8213 8.49605 8.88001C8.42435 8.92847 8.36566 8.9938 8.32514 9.07027C8.28462 9.14673 8.26352 9.23198 8.26367 9.31851V14.0403C8.26367 14.3595 8.49077 14.6003 8.7918 14.6003C9.96473 14.6003 11.1413 14.7098 11.846 15.3759C11.8557 15.3851 11.8678 15.3912 11.8809 15.3935C11.8939 15.3959 11.9074 15.3943 11.9196 15.389C11.9318 15.3837 11.9422 15.375 11.9495 15.3639C11.9568 15.3527 11.9606 15.3397 11.9605 15.3264V9.7628C11.9606 9.72526 11.9525 9.68816 11.937 9.65399C11.9215 9.61982 11.8988 9.58939 11.8704 9.56475C11.709 9.42675 11.5297 9.31117 11.3374 9.22114Z"
          fill={color}
        />
        <path
          d="M15.9528 8.87952C15.8654 8.82096 15.7622 8.79045 15.657 8.79205C14.5412 8.797 13.7078 8.9358 13.1115 9.22115C12.9191 9.31102 12.7398 9.42638 12.5782 9.56411C12.55 9.58879 12.5273 9.61923 12.5118 9.65339C12.4963 9.68755 12.4883 9.72463 12.4883 9.76215V15.3261C12.4883 15.3389 12.492 15.3514 12.4991 15.362C12.5062 15.3727 12.5162 15.381 12.528 15.3859C12.5398 15.3909 12.5527 15.3923 12.5653 15.3899C12.5778 15.3875 12.5894 15.3814 12.5985 15.3725C13.0222 14.9516 13.7657 14.5998 15.6577 14.5999C15.7978 14.5999 15.9321 14.5443 16.0311 14.4453C16.1302 14.3462 16.1858 14.2119 16.1858 14.0718V9.31869C16.186 9.23198 16.1649 9.14656 16.1242 9.06997C16.0836 8.99338 16.0247 8.92797 15.9528 8.87952Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9895_81537">
          <rect width="8.45" height="8.45" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ProjectIcon = ({ color }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
      <path
        d="M28 16C28 9.375 22.625 4 16 4C9.375 4 4 9.375 4 16C4 22.625 9.375 28 16 28C22.625 28 28 22.625 28 16Z"
        fill="white"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M17.1719 9.3335V11.3127H16.3906C15.9591 11.3127 15.6094 11.6624 15.6094 12.0939C15.6094 12.5254 15.9591 12.8752 16.3906 12.8752H17.1719V14.8283H19.5156V13.6564C19.5156 13.4409 19.6907 13.2658 19.9062 13.2658C20.1218 13.2658 20.2969 13.4409 20.2969 13.6564V14.8283H22.6667V9.3335H17.1719Z"
        fill={color}
      />
      <path
        d="M11.1038 17.5625C11.1038 17.994 11.4536 18.3438 11.8851 18.3438C12.3166 18.3438 12.6663 17.994 12.6663 17.5625V16.7812H14.4372V14.8281H13.6559C13.4402 14.8281 13.2653 14.6532 13.2653 14.4375C13.2653 14.2218 13.4402 14.0469 13.6559 14.0469H14.4372V11.7031H9.33301V16.7812H11.1038V17.5625Z"
        fill={color}
      />
      <path
        d="M18.5521 17.5625V16.7812C18.5521 16.3498 18.2023 16 17.7708 16C17.3393 16 16.9896 16.3498 16.9896 16.7812V17.5625H15.2188V18.5521C16.0805 18.5521 16.7812 19.2528 16.7812 20.1146C16.7812 20.9763 16.0805 21.6771 15.2188 21.6771V22.6667H20.2969V17.5625H18.5521Z"
        fill={color}
      />
      <path
        d="M15.2184 20.8958C15.6499 20.8958 15.9997 20.5461 15.9997 20.1146C15.9997 19.6831 15.6499 19.3333 15.2184 19.3333H14.4372V17.5625H13.4476C13.4476 18.4242 12.7468 19.125 11.8851 19.125C11.0233 19.125 10.3226 18.4242 10.3226 17.5625H9.33301V22.6667H14.4372V20.8958H15.2184Z"
        fill={color}
      />
    </svg>
  );
};

const QuizIcon = ({ color }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z"
        stroke={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3313 12.8504C11.2828 13.224 11.5594 13.5323 11.889 13.5323H12.1626C12.3239 13.5323 12.4573 13.3887 12.4675 13.2043C12.5006 12.8531 12.6317 12.5466 12.8608 12.2849L13.227 11.871C13.5133 11.5412 13.7137 11.2419 13.8282 10.9731C13.9427 10.7007 14 10.4122 14 10.1075C14 9.43727 13.8237 8.91935 13.4711 8.55377C13.1185 8.18459 12.6227 8 11.9838 8C11.3509 8 10.8506 8.19355 10.4829 8.58065C10.2649 8.81211 10.1109 9.09675 10.0211 9.43457C9.91413 9.83693 10.2303 10.1882 10.5974 10.1882C10.9081 10.1882 11.1398 9.89815 11.356 9.62752C11.3862 9.58973 11.4161 9.55232 11.4458 9.51613C11.5814 9.35125 11.7608 9.26882 11.9838 9.26882C12.454 9.26882 12.689 9.57169 12.689 10.1774C12.689 10.3781 12.6438 10.5699 12.5534 10.7527C12.463 10.9319 12.2806 11.1667 12.0064 11.457C11.7352 11.7437 11.5483 12.0359 11.4458 12.3333C11.3951 12.4807 11.3569 12.653 11.3313 12.8504ZM11.3645 14.5914C11.2288 14.7455 11.161 14.9427 11.161 15.1828C11.161 15.4193 11.2273 15.6147 11.3599 15.7688C11.4956 15.9229 11.6734 16 11.8934 16C12.1134 16 12.2897 15.9229 12.4223 15.7688C12.5579 15.6147 12.6257 15.4193 12.6257 15.1828C12.6257 14.9427 12.5564 14.7455 12.4178 14.5914C12.2821 14.4337 12.1073 14.3549 11.8934 14.3549C11.6794 14.3549 11.5031 14.4337 11.3645 14.5914Z"
        fill={color}
      />
    </svg>
  );
};

const TestIcon = ({ color }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 16C28 9.375 22.625 4 16 4C9.375 4 4 9.375 4 16C4 22.625 9.375 28 16 28C22.625 28 28 22.625 28 16Z"
        fill="white"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M13.916 21.0003H10.9993V11.0003H15.166V12.667C15.166 12.888 15.2538 13.1 15.4101 13.2563C15.5664 13.4125 15.7783 13.5003 15.9993 13.5003H17.666V15.5837C17.666 15.6942 17.7099 15.8002 17.7881 15.8783C17.8662 15.9564 17.9722 16.0003 18.0827 16.0003C18.1932 16.0003 18.2992 15.9564 18.3773 15.8783C18.4555 15.8002 18.4993 15.6942 18.4993 15.5837V13.0837C18.4997 13.0288 18.4892 12.9745 18.4684 12.9237C18.4477 12.8729 18.4171 12.8268 18.3785 12.7878L15.8785 10.2878C15.8396 10.2492 15.7934 10.2187 15.7426 10.1979C15.6919 10.1772 15.6375 10.1667 15.5827 10.167H10.9993C10.7783 10.167 10.5664 10.2548 10.4101 10.4111C10.2538 10.5674 10.166 10.7793 10.166 11.0003V21.0003C10.166 21.2213 10.2538 21.4333 10.4101 21.5896C10.5664 21.7459 10.7783 21.8337 10.9993 21.8337H13.916C14.0265 21.8337 14.1325 21.7898 14.2106 21.7116C14.2888 21.6335 14.3327 21.5275 14.3327 21.417C14.3327 21.3065 14.2888 21.2005 14.2106 21.1224C14.1325 21.0442 14.0265 21.0003 13.916 21.0003ZM15.9993 11.5878L17.0785 12.667H15.9993V11.5878Z"
        fill={color}
      />
      <path
        d="M12.2497 15.1668H14.333C14.4435 15.1668 14.5495 15.1229 14.6276 15.0448C14.7058 14.9667 14.7497 14.8607 14.7497 14.7502C14.7497 14.6397 14.7058 14.5337 14.6276 14.4555C14.5495 14.3774 14.4435 14.3335 14.333 14.3335H12.2497C12.1392 14.3335 12.0332 14.3774 11.955 14.4555C11.8769 14.5337 11.833 14.6397 11.833 14.7502C11.833 14.8607 11.8769 14.9667 11.955 15.0448C12.0332 15.1229 12.1392 15.1668 12.2497 15.1668Z"
        fill={color}
      />
      <path
        d="M16.833 16.4172C16.833 16.3066 16.7891 16.2007 16.711 16.1225C16.6328 16.0444 16.5268 16.0005 16.4163 16.0005H12.2497C12.1392 16.0005 12.0332 16.0444 11.955 16.1225C11.8769 16.2007 11.833 16.3066 11.833 16.4172C11.833 16.5277 11.8769 16.6336 11.955 16.7118C12.0332 16.7899 12.1392 16.8338 12.2497 16.8338H16.4163C16.5268 16.8338 16.6328 16.7899 16.711 16.7118C16.7891 16.6336 16.833 16.5277 16.833 16.4172Z"
        fill={color}
      />
      <path
        d="M12.2497 17.667C12.1392 17.667 12.0332 17.7109 11.955 17.789C11.8769 17.8672 11.833 17.9732 11.833 18.0837C11.833 18.1942 11.8769 18.3001 11.955 18.3783C12.0332 18.4564 12.1392 18.5003 12.2497 18.5003H15.583C15.6935 18.5003 15.7995 18.4564 15.8776 18.3783C15.9558 18.3001 15.9997 18.1942 15.9997 18.0837C15.9997 17.9732 15.9558 17.8672 15.8776 17.789C15.7995 17.7109 15.6935 17.667 15.583 17.667H12.2497Z"
        fill={color}
      />
      <path
        d="M20.5827 15.167H20.5119C20.1805 15.1673 19.8627 15.2992 19.6285 15.5337L15.7869 19.3753C15.6863 19.4796 15.6157 19.6092 15.5827 19.7503L15.166 21.317C15.1484 21.3867 15.1491 21.4598 15.1681 21.5292C15.1871 21.5985 15.2237 21.6618 15.2744 21.7128C15.3274 21.7671 15.3942 21.8059 15.4676 21.825C15.5411 21.8441 15.6183 21.8427 15.691 21.8212L17.2494 21.417C17.3968 21.3814 17.5311 21.3048 17.6369 21.1962L21.4785 17.3545C21.7044 17.1224 21.8314 16.8117 21.8327 16.4878V16.417C21.8327 16.0855 21.701 15.7675 21.4666 15.5331C21.2322 15.2987 20.9142 15.167 20.5827 15.167ZM17.8744 19.7878L17.2119 19.1253L19.541 16.7962L20.2035 17.4587L17.8744 19.7878ZM17.041 20.6212L16.1577 20.842L16.3785 19.9587L16.6285 19.7087L17.291 20.3712L17.041 20.6212ZM20.9994 16.4878C20.9997 16.5427 20.9892 16.597 20.9685 16.6478C20.9477 16.6986 20.9172 16.7447 20.8785 16.7837L20.791 16.8712L20.1285 16.2087L20.216 16.1212C20.255 16.0825 20.3012 16.052 20.3519 16.0313C20.4027 16.0105 20.457 16 20.5119 16.0003H20.5827C20.6932 16.0003 20.7992 16.0442 20.8773 16.1224C20.9555 16.2005 20.9994 16.3065 20.9994 16.417V16.4878Z"
        fill={color}
      />
    </svg>
  );
};

const VideoIcon = ({ color }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 3.5C16.6926 3.5 20.5 7.30739 20.5 12C20.5 16.6926 16.6926 20.5 12 20.5C7.30739 20.5 3.5 16.6926 3.5 12C3.5 7.30739 7.30739 3.5 12 3.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M11.167 16.0515L15.3431 12.5767C15.4277 12.5063 15.4958 12.4182 15.5425 12.3185C15.5892 12.2189 15.6135 12.1102 15.6135 12.0001C15.6135 11.8901 15.5892 11.7814 15.5425 11.6817C15.4958 11.5821 15.4277 11.4939 15.3431 11.4236L11.167 7.94872C11.0575 7.85765 10.9243 7.79965 10.7831 7.7815C10.6418 7.76336 10.4983 7.78581 10.3693 7.84624C10.2403 7.90668 10.1312 8.00259 10.0547 8.12275C9.97828 8.24292 9.93761 8.38238 9.9375 8.52481V15.4754C9.93761 15.6179 9.97828 15.7573 10.0547 15.8775C10.1312 15.9977 10.2403 16.0936 10.3693 16.154C10.4983 16.2144 10.6418 16.2369 10.7831 16.2187C10.9243 16.2006 11.0575 16.1426 11.167 16.0515Z"
        fill={color}
      />
    </svg>
  );
};

const CodeQuizIcon = ({ color }: IconProps) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
        fill="white"
        stroke={color}
        strokeMiterlimit="10"
      />
      <g clipPath="url(#clip0_2475_73084)">
        <path
          d="M10.5 16.4636C10.3675 16.4637 10.2396 16.4156 10.1401 16.3282L6.80675 13.4115C6.74812 13.3602 6.70114 13.2969 6.66896 13.226C6.63677 13.155 6.62012 13.078 6.62012 13.0001C6.62012 12.9222 6.63677 12.8451 6.66896 12.7742C6.70114 12.7032 6.74812 12.6399 6.80675 12.5886L10.1401 9.67195C10.2492 9.57643 10.3919 9.52819 10.5366 9.53783C10.6813 9.54748 10.8163 9.61422 10.9118 9.72338C11.0073 9.83254 11.0556 9.97518 11.0459 10.1199C11.0363 10.2646 10.9696 10.3996 10.8604 10.4951L7.99711 13.0001L10.8601 15.505C10.9439 15.5783 11.0034 15.6754 11.0305 15.7833C11.0577 15.8912 11.0513 16.0049 11.0121 16.1091C10.973 16.2133 10.903 16.303 10.8115 16.3664C10.7199 16.4297 10.6113 16.4637 10.5 16.4636Z"
          fill={color}
        />
        <path
          d="M15.4995 16.4636C15.3882 16.4637 15.2795 16.4298 15.1879 16.3665C15.0964 16.3032 15.0263 16.2134 14.9871 16.1092C14.948 16.005 14.9415 15.8913 14.9687 15.7834C14.9958 15.6754 15.0553 15.5783 15.1391 15.505L18.0024 13.0001L15.1393 10.4951C15.0302 10.3996 14.9634 10.2646 14.9538 10.1199C14.9441 9.97518 14.9924 9.83254 15.0879 9.72338C15.1834 9.61422 15.3184 9.54748 15.4631 9.53783C15.6079 9.52819 15.7505 9.57643 15.8597 9.67195L19.193 12.5886C19.2516 12.6399 19.2986 12.7032 19.3308 12.7742C19.363 12.8451 19.3796 12.9222 19.3796 13.0001C19.3796 13.078 19.363 13.155 19.3308 13.226C19.2986 13.2969 19.2516 13.3602 19.193 13.4115L15.8597 16.3282C15.7601 16.4157 15.632 16.4639 15.4995 16.4636Z"
          fill={color}
        />
        <path
          d="M11.7499 17.7136C11.6647 17.7136 11.5807 17.6937 11.5046 17.6555C11.4285 17.6172 11.3624 17.5618 11.3115 17.4935C11.2606 17.4252 11.2264 17.346 11.2115 17.2621C11.1967 17.1783 11.2016 17.0921 11.2259 17.0105L13.7259 8.67714C13.7448 8.60643 13.7776 8.54021 13.8225 8.48241C13.8674 8.42462 13.9234 8.37642 13.9873 8.34068C14.0511 8.30493 14.1215 8.28236 14.1942 8.27431C14.267 8.26627 14.3406 8.2729 14.4107 8.29381C14.4808 8.31473 14.546 8.34951 14.6025 8.39609C14.6589 8.44267 14.7054 8.50011 14.7393 8.56499C14.7731 8.62988 14.7936 8.7009 14.7995 8.77384C14.8054 8.84678 14.7966 8.92016 14.7736 8.98964L12.2736 17.323C12.2399 17.4358 12.1708 17.5347 12.0765 17.6051C11.9821 17.6755 11.8676 17.7135 11.7499 17.7136Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2475_73084">
          <rect
            width="13.3333"
            height="13.3333"
            fill={color}
            transform="translate(6.33301 6.3335)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const SectionResourceIcon = ({ color }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3.5C16.6926 3.5 20.5 7.30739 20.5 12C20.5 16.6926 16.6926 20.5 12 20.5C7.30739 20.5 3.5 16.6926 3.5 12C3.5 7.30739 7.30739 3.5 12 3.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <g clipPath="url(#clip0_9895_81537)">
        <path
          d="M11.3374 9.22114C10.7411 8.93645 9.90763 8.79699 8.7918 8.79204C8.68656 8.79061 8.58339 8.8213 8.49605 8.88001C8.42435 8.92847 8.36566 8.9938 8.32514 9.07027C8.28462 9.14673 8.26352 9.23198 8.26367 9.31851V14.0403C8.26367 14.3595 8.49077 14.6003 8.7918 14.6003C9.96473 14.6003 11.1413 14.7098 11.846 15.3759C11.8557 15.3851 11.8678 15.3912 11.8809 15.3935C11.8939 15.3959 11.9074 15.3943 11.9196 15.389C11.9318 15.3837 11.9422 15.375 11.9495 15.3639C11.9568 15.3527 11.9606 15.3397 11.9605 15.3264V9.7628C11.9606 9.72526 11.9525 9.68816 11.937 9.65399C11.9215 9.61982 11.8988 9.58939 11.8704 9.56475C11.709 9.42675 11.5297 9.31117 11.3374 9.22114Z"
          fill={color}
        />
        <path
          d="M15.9528 8.87952C15.8654 8.82096 15.7622 8.79045 15.657 8.79205C14.5412 8.797 13.7078 8.9358 13.1115 9.22115C12.9191 9.31102 12.7398 9.42638 12.5782 9.56411C12.55 9.58879 12.5273 9.61923 12.5118 9.65339C12.4963 9.68755 12.4883 9.72463 12.4883 9.76215V15.3261C12.4883 15.3389 12.492 15.3514 12.4991 15.362C12.5062 15.3727 12.5162 15.381 12.528 15.3859C12.5398 15.3909 12.5527 15.3923 12.5653 15.3899C12.5778 15.3875 12.5894 15.3814 12.5985 15.3725C13.0222 14.9516 13.7657 14.5998 15.6577 14.5999C15.7978 14.5999 15.9321 14.5443 16.0311 14.4453C16.1302 14.3462 16.1858 14.2119 16.1858 14.0718V9.31869C16.186 9.23198 16.1649 9.14656 16.1242 9.06997C16.0836 8.99338 16.0247 8.92797 15.9528 8.87952Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9895_81537">
          <rect width="8.45" height="8.45" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CodingAssessmentIcon = ({ color }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 3.5C16.6926 3.5 20.5 7.30739 20.5 12C20.5 16.6926 16.6926 20.5 12 20.5C7.30739 20.5 3.5 16.6926 3.5 12C3.5 7.30739 7.30739 3.5 12 3.5Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M10.5 13.75L8.5 12L10.5 10.25"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 13.75L15.5 12L13.5 10.25"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6508 12.7831C11.6296 12.6298 11.6384 12.494 11.6773 12.3756C11.7161 12.2572 11.7708 12.1493 11.8414 12.0518C11.9155 11.9543 11.9914 11.862 12.0691 11.7749C12.1468 11.6879 12.2121 11.6026 12.265 11.519C12.318 11.4354 12.3444 11.3466 12.3444 11.2526C12.3444 11.1273 12.3056 11.0263 12.228 10.9497C12.1538 10.873 12.0497 10.8347 11.9155 10.8347C11.8061 10.8347 11.7073 10.8591 11.619 10.9079C11.5308 10.9566 11.4478 11.0228 11.3701 11.1064L11.0312 10.7982C11.1477 10.6659 11.2872 10.5579 11.4496 10.4743C11.612 10.3873 11.792 10.3438 11.9897 10.3438C12.265 10.3438 12.4909 10.4169 12.6674 10.5631C12.8475 10.7094 12.9375 10.9253 12.9375 11.2108C12.9375 11.3431 12.9093 11.4615 12.8528 11.566C12.7998 11.6705 12.7328 11.768 12.6516 11.8585C12.5739 11.9491 12.4962 12.0396 12.4186 12.1301C12.3409 12.2207 12.2791 12.3182 12.2332 12.4226C12.1874 12.5271 12.1715 12.6472 12.1856 12.7831H11.6508ZM11.9208 13.9688C11.8114 13.9688 11.7179 13.9304 11.6402 13.8538C11.5661 13.7772 11.529 13.6797 11.529 13.5613C11.529 13.4429 11.5661 13.3454 11.6402 13.2688C11.7179 13.1922 11.8114 13.1539 11.9208 13.1539C12.0338 13.1539 12.1273 13.1922 12.2015 13.2688C12.2791 13.3454 12.318 13.4429 12.318 13.5613C12.318 13.6797 12.2791 13.7772 12.2015 13.8538C12.1273 13.9304 12.0338 13.9688 11.9208 13.9688Z"
        fill={color}
      />
    </svg>
  );
};

const CodingQuizIcon = ({ color }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
        fill="white"
        stroke={color}
        strokeMiterlimit="10"
      />
      <g clipPath="url(#clip0_2475_73084)">
        <path
          d="M10.5 16.4636C10.3675 16.4637 10.2396 16.4156 10.1401 16.3282L6.80675 13.4115C6.74812 13.3602 6.70114 13.2969 6.66896 13.226C6.63677 13.155 6.62012 13.078 6.62012 13.0001C6.62012 12.9222 6.63677 12.8451 6.66896 12.7742C6.70114 12.7032 6.74812 12.6399 6.80675 12.5886L10.1401 9.67195C10.2492 9.57643 10.3919 9.52819 10.5366 9.53783C10.6813 9.54748 10.8163 9.61422 10.9118 9.72338C11.0073 9.83254 11.0556 9.97518 11.0459 10.1199C11.0363 10.2646 10.9696 10.3996 10.8604 10.4951L7.99711 13.0001L10.8601 15.505C10.9439 15.5783 11.0034 15.6754 11.0305 15.7833C11.0577 15.8912 11.0513 16.0049 11.0121 16.1091C10.973 16.2133 10.903 16.303 10.8115 16.3664C10.7199 16.4297 10.6113 16.4637 10.5 16.4636Z"
          fill={color}
        />
        <path
          d="M15.4995 16.4636C15.3882 16.4637 15.2795 16.4298 15.1879 16.3665C15.0964 16.3032 15.0263 16.2134 14.9871 16.1092C14.948 16.005 14.9415 15.8913 14.9687 15.7834C14.9958 15.6754 15.0553 15.5783 15.1391 15.505L18.0024 13.0001L15.1393 10.4951C15.0302 10.3996 14.9634 10.2646 14.9538 10.1199C14.9441 9.97518 14.9924 9.83254 15.0879 9.72338C15.1834 9.61422 15.3184 9.54748 15.4631 9.53783C15.6079 9.52819 15.7505 9.57643 15.8597 9.67195L19.193 12.5886C19.2516 12.6399 19.2986 12.7032 19.3308 12.7742C19.363 12.8451 19.3796 12.9222 19.3796 13.0001C19.3796 13.078 19.363 13.155 19.3308 13.226C19.2986 13.2969 19.2516 13.3602 19.193 13.4115L15.8597 16.3282C15.7601 16.4157 15.632 16.4639 15.4995 16.4636Z"
          fill={color}
        />
        <path
          d="M11.7499 17.7136C11.6647 17.7136 11.5807 17.6937 11.5046 17.6555C11.4285 17.6172 11.3624 17.5618 11.3115 17.4935C11.2606 17.4252 11.2264 17.346 11.2115 17.2621C11.1967 17.1783 11.2016 17.0921 11.2259 17.0105L13.7259 8.67714C13.7448 8.60643 13.7776 8.54021 13.8225 8.48241C13.8674 8.42462 13.9234 8.37642 13.9873 8.34068C14.0511 8.30493 14.1215 8.28236 14.1942 8.27431C14.267 8.26627 14.3406 8.2729 14.4107 8.29381C14.4808 8.31473 14.546 8.34951 14.6025 8.39609C14.6589 8.44267 14.7054 8.50011 14.7393 8.56499C14.7731 8.62988 14.7936 8.7009 14.7995 8.77384C14.8054 8.84678 14.7966 8.92016 14.7736 8.98964L12.2736 17.323C12.2399 17.4358 12.1708 17.5347 12.0765 17.6051C11.9821 17.6755 11.8676 17.7135 11.7499 17.7136Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2475_73084">
          <rect
            width="13.3333"
            height="13.3333"
            fill={color}
            transform="translate(6.33301 6.3335)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const LessonTypeIcons = ({ component, variant }: IconographyType) => {
  const iconColor = useMemo(() => {
    switch (variant) {
      case 'current':
        return 'white';
      case 'default':
        return '#757575';
      case 'incomplete':
        return 'white';
      case 'upcoming':
        return 'white';
      default:
        return '';
    }
  }, [variant]);

  const icons: Icon = useMemo(() => {
    return {
      video: <VideoIcon color={iconColor} />,
      test: <TestIcon color={iconColor} />,
      quiz: <QuizIcon color={iconColor} />,
      project: <ProjectIcon color={iconColor} />,
      notes: <NotesIcon color={iconColor} />,
      assignment: <AssignmentIcon color={iconColor} />,
      code: <CodeQuizIcon color={iconColor} />,
      sectionResource: <SectionResourceIcon color={iconColor} />,
      coding_assessment: <CodingAssessmentIcon color={iconColor} />,
      coding_quiz: <CodingQuizIcon color={iconColor} />,
    };
  }, [iconColor]);

  return <div>{icons[component]}</div>;
};

export default LessonTypeIcons;
