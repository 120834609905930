import { create } from 'zustand';

type VideoLessonCompletedStore = {
  completedLessons: string[];
  updateCompletedLessons: (list: string[]) => void;
  clearCompletedLessons: () => void;
};

type VideoLoadingStore = {
  isLoading: boolean;
  updateLoadingStatus: (val: boolean) => void;
};

export const useVideoLoadingStatus = create<VideoLoadingStore>()((set) => ({
  isLoading: false,
  updateLoadingStatus: (val) => set(() => ({ isLoading: val })),
}));

const useVideoLessonCompleted = create<VideoLessonCompletedStore>()((set) => ({
  completedLessons: [],
  updateCompletedLessons: (data) => {
    set(() => ({ completedLessons: data }));
  },
  clearCompletedLessons: () => {
    set({ completedLessons: [] });
  },
}));

export default useVideoLessonCompleted;
